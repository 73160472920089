<template>
  <div class="home-about">
    <div class="about">
      <!-- <img
        :src="data.logo"
        width="341px"
        alt="goinix-logo"
      /> -->
      <iframe class="about-d3" :src="data.d3Link"></iframe>
      <span class="company-name">{{ data.companyName }}</span>
      <span class="company-desc">{{ data.companyDesc }}</span>
    </div>
    <div class="about-detail">
      <div class="about-detail-item" v-for="(item, index) in data.detail" :key="index">
        <img
          class="about-detail-item-icon"
          :src="item.icon"
          :alt="item.title"
        />
        <!-- <span class="about-detail-item-title">{{ item.title }}</span> -->
        <span class="about-detail-item-desc">{{ item.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-about",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.home-about {
  text-align: center;
  margin: 0 auto;
  padding: 40px 30px 110px 30px;
  box-sizing: border-box;
  line-height: 26px;
  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .about-d3 {
      width: 300px !important;
      height: 300px !important;
      margin-bottom: 30px;
      box-sizing: border-box;
    }
    .company-name {
      font-size: $font-size-h3;
      font-weight: bold;
    }
    .company-desc {
      margin-top: 30px;
      font-size: $font-size-small;
      white-space: pre-wrap;
    }
  }

  .about-detail {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 10px;
    .about-detail-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% / 3);
      height: 300px;
      padding: 10px;
      box-sizing: border-box;
      .about-detail-item-icon {
        width: 100%;
        height: 80%;
        object-fit: contain;
      }
      .about-detail-item-title {
        color: #c00000;
        line-height: $font-size-h2;
        font-size: $font-size-h3;
        margin: 20px 0;
      }
      .about-detail-item-desc {
        height: 20%;
        margin-top: 10px;
        font-size: $font-size-small;
      white-space: pre-wrap;

      }
    }
  }
}

@media (max-width: 768px) {
  .home-about {
    padding: 30px !important;
  }
  .about-detail-item {
    height: 250px !important;
    .about-detail-item-desc {
      font-size: $font-size-xsmall !important;
    }
  }
}
</style>
<template>
  <b-navbar
    toggleable="xl"
    type="dark"
    variant="dark"
    style="width: 100%"
    sticky="top"
  >
    <div class="container">
      <b-navbar-brand href="#home">
        <div style="display: flex; align-items: center">
          <iframe class="logo" :src="navMenu.logoD3"></iframe>
          <img
            class="logo-3d"
            :src="navMenu.descLogo"
            alt="3D I-DM"
          />
        </div>
        <!-- <img
          class="logo"
          :src="navMenu.logo"
          alt="logo"
        /> -->
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="menu-list" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            class="menu-item"
            v-for="(item, index) in navMenu.menus"
            :key="index"
            @click="handelNavClick(item)"
            >{{ item.name }}</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
export default {
  props: {
    navMenu: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handelNavClick(item) {
      this.goRule(item.to);
    },
    //锚点跳转
    goRule(id) {
      if (id.startsWith("#")) {
        document.querySelector(id).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        window.open(id, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-dark {
  --bs-bg-opacity: 0.9 !important;
}
.container {
  padding-right: 100px;
  padding-left: 50px;
  max-width: 100%;
  // height: 70px;
  box-sizing: border-box;
  // background: #000;
  .logo {
    // width: 48px;
    // height: 48px;
    width: 60px;
    height: 60px;
  }
  .logo-3d {
    width: auto;
    height: 48px;
  }

  .menu-list {
    display: flex;
    justify-content: flex-end;

    .menu-item {
      margin-left: 80px;
      a {
        color: #fff;
      }
    }
  }
}
.nav-link {
  &:hover {
    color: #c00000 !important;
  }
}
@media (max-width: 768px) {
  .container {
    width: 750px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    .logo {
      width: 48px;
      height: 48px;
    }
    .logo-3d {
      width: auto;
      height: 36px;
    }
  }
}
</style>

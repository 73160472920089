<template>
  <div class="partner" id="partner">
    <div class="partner-title margin-bottom-30">合作伙伴及客户</div>
    <div class="partner-list">
      <img :src="data.image" :alt="data.title" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.partner {
  width: 100%;
  padding: 110px 75px;
  box-sizing: border-box;
  .partner-title {
    color: #3d3d3d;
    font-size: $font-size-h3;
    font-weight: bold;
  }
  .partner-list {
    width: 100%;
    padding: 0px 50px;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
}
</style>
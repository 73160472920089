<template>
  <div class="home-challenge start-bg">
    <div class="challenge-title">{{ data.title }}</div>
    <div class="challenge-list">
      <div
        class="challenge-item"
        v-for="(item, index) in data.list"
        :key="index"
      >
        <img
          class="challenge-item-icon"
          :src="item.image"
          :alt="item.title"
        />
        <span class="challenge-item-title">{{ item.title }}</span>
        <span class="challenge-item-desc">{{ item.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.home-challenge {
  text-align: center;
  width: 100%;
  // height: 500px;
  padding: 110px 75px;
  box-sizing: border-box;

  .challenge-title {
    color: #fff;
    font-size: $font-size-h3;
    font-weight: bold;
  }
  .challenge-list {
    display: flex;
    justify-content: space-around;
    // flex-wrap: wrap;
    margin-top: 50px;
    .challenge-item {
      width: 375px;
      height: 250px;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background: #25282d;
      margin-bottom: 30px;
      .challenge-item-icon {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
        object-fit: contain;
      }
      .challenge-item-title {
        color: #fff;
        font-weight: bold;
        font-size: $font-size-base;
        margin-bottom: 10px;
        white-space: pre-wrap;
      }
      .challenge-item-desc {
        color: #fff;
        width: 80%;
        text-align: center;
        margin: 0 auto;
        white-space: pre-wrap;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1378px) {
  .challenge-item {
    width: 275px !important;
    height: 180px !important;
    .challenge-item-icon {
      width: 60px !important;
      height: 60px !important;
    }
    .challenge-item-title {
      font-size: $font-size-small !important;
    }
    .challenge-item-desc {
      font-size: $font-size-xsmall !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .challenge-list {
    flex-wrap: wrap !important;
  }
  .challenge-item-title {
    font-size: $font-size-small !important;
  }
  .challenge-item-desc {
    font-size: $font-size-xsmall;
  }
}
</style>
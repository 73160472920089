<template>
  <div class="home-scenario" id="scenario">
    <div class="scenario-title">{{ data.title }}</div>
    <div class="scenario-list">
      <div
        class="scenario-item"
        v-for="(item, index) in data.list"
        :key="index"
      >
        <img
          class="scenario-item-icon"
          :src="item.image"
          :alt="item.title"
        />
        <span class="scenario-item-title">{{ item.title }}</span>
        <span class="scenario-item-desc">{{ item.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.home-scenario {
  text-align: center;
  width: 100%;
  padding: 100px 30px 30px 30px;
  box-sizing: border-box;
  background: #000;
  .scenario-title {
    color: #fff;
    font-size: $font-size-h3;
  }
  .scenario-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;
    .scenario-item {
      width: 375px;
      // height: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      margin-bottom: 30px;
      .scenario-item-icon {
        width: 100%;
        height: 300px;
        object-fit: contain;
      }
      .scenario-item-title {
        color: #fff;
        margin: 15px 0;
        font-size: $font-size-h4;
      }
      .scenario-item-desc {
        color: #fff;
        font-size: $font-size-small;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
<template>
  <div class="home-increase">
    <div class="home-increase-title">{{ data.title }}</div>
    <div class="home-increase-list">
      <div
        class="home-increase-item"
        v-for="(item, index) in data.list"
        :key="index"
      >
        <div class="increase-num">
          <countTo
            ref="countTo"
            :startVal="startVal"
            :endVal="parseFloat(item.data)"
            :duration="duration"
          ></countTo>
          <span>{{ item.unit }}</span>
          <div class="increase-icon">+</div>
        </div>
        <div class="increase-name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  components: { countTo },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      // 需要滚动的时间
      duration: 1500,
      // 初始值
      startVal: 0,
      timeInterval: null,
    };
  },
  mounted() {
    this.startCount();
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  methods: {
    startCount() {
      this.timeInterval = setInterval(() => {
        if (this.startVal < 3) {
          this.startVal++;
        } else {
          this.startVal = 0;
        }
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-increase {
  width: 100%;
  padding: 110px 30px;
  .home-increase-title {
    color: #3d3d3d;
    font-size: $font-size-h3;
    font-weight: bold;
    margin-bottom: 50px;
  }
  .home-increase-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .home-increase-item {
      width: 225px;
      color: #3d3d3d;
      .increase-num {
        font-size: $font-size-h1;
        font-weight: bolder;
        display: flex;
        justify-content: center;
        .increase-icon {
          color: #c00000;
          vertical-align: top;
        }
      }
      .increase-name {
        opacity: 0.6;
      }
    }
  }
}
</style>
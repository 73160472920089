<template>
  <div class="home-contact">
    <video
      class="home-contact-video"
      :src="data.background"
      autoplay
      muted
      loop
    ></video>
    <div class="home-contact-content" id="contact">
      <div class="home-contact-title">{{ data.title }}</div>
      <img class="home-contact-img" :src="data.contactUsImg" />
      <div>
        <div class="home-contact-company">{{ data.company }}</div>
        <div class="home-contact-copyright">
          <a href="https://beian.miit.gov.cn/" target="_blank">{{
            data.copyright
          }}</a>
        </div>
        <!-- <div>
          <span>{{ data.tel }}</span>
          <span>{{ data.email }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.home-contact {
  width: 100%;
  height: 800px;
  box-sizing: border-box;
  color: #e5e5e5;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  background: #000;
  .home-contact-video {
    width: 100%;
    height: 99%;
    object-fit: cover;
  }
  // .home-contact-title {
  //   font-size: $font-size-h3;
  //   color: #fff;
  //   margin: 0 0 50px 0;
  // }
  // .home-contact-company {
  //   margin-top: 50px;
  //   margin-bottom: 10px;
  // }

  .home-contact-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .home-contact-title {
      font-size: $font-size-h3;
      font-weight: bold;

      color: #fff;
      margin-top: 110px;
    }
    .home-contact-img {
      width: 30%;
      object-fit: contain;
    }
    .home-contact-company {
      margin-top: 50px;
      margin-bottom: 10px;
    }
    .home-contact-copyright {
      margin-bottom: 50px;
    }
  }
}
@media (max-width: 768px) {
  .home-contact-img {
    width: 80% !important;
    height: auto;
    object-fit: contain;
  }
  .home-contact-company {
    font-size: 12px !important;
  }
  .home-contact-copyright {
    font-size: 12px !important;
  }
}
</style>
<template>
  <div class="home-business">
    <div class="business-container">
      <div class="business-item" v-for="(item, index) in data" :key="index">
        <img :src="item.image" alt="" />
        <!-- <div class="icon" :style="`background-image: url(${item.icon})`"></div> -->
        <div class="text">
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.home-business {
  height: 650px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .business-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    .business-item {
      width: 25%;
      transition: width 0.5s;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .icon {
        width: 50px;
        height: 50px;
        background-size: 100%;
        position: absolute;
        left: 0;
        top: -145px;
        bottom: 0;
        margin: auto;
        opacity: 0;
        transform: rotate(-300deg);
      }
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        transform: translateX(-50%);
        text-align: center;
        color: #fff;
        .title {
          font-size: $font-size-h4;
        }
      }
      &:hover {
        width: 100%;
        .text {
          left: 5%;
          text-align: left;
          transform: translateX(0);
        }
        .icon {
          left: 5%;
          opacity: 1;
        }
      }
    }
  }
}
@media only screen and (max-width: 1680px) {
  .home-business {
    height: 550px;
  }
}
@media only screen and (max-width: 1400px) {
  .home-business {
    height: 460px;
  }
}
@media only screen and (max-width: 1280px) {
  .home-business {
    height: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .home-business {
    height: auto;
    .business-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .business-item {
        width: 100%;
        height: 230px;
        .text {
          .title {
            font-size: $font-size-base;
          }
          .desc {
            font-size: 16px;
          }
        }
        &:hover {
          width: 100%;
        }
      }
    }
  }
}
</style>
<template>
  <div class="home">
    <home-header :navMenu="navMenu"></home-header>
    <home-banner :banners="banners"></home-banner>
    <home-business :data="business"></home-business>
    <home-about :data="about"></home-about>
    <home-challenge :data="challenge"></home-challenge>
    <home-solution :data="solution"></home-solution>
    <!-- <home-scenario :data="scenario"></home-scenario> -->
    <home-increase :data="increase"></home-increase>
    <home-workprocess :data="workProcess"></home-workprocess>
    <home-partner :data="partner"></home-partner>
    <home-contact :data="contact"></home-contact>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations } from "vuex";
import homeHeader from "@/components/home-header.vue";
import homeBanner from "@/components/home-banner.vue";
import homeBusiness from "@/components/home-business.vue";
import homeAbout from "@/components/home-about.vue";
import homeChallenge from "@/components/home-challenge.vue";
import homeSolution from "@/components/home-solution.vue";
import homeScenario from "@/components/home-scenario.vue";
import homeIncrease from "@/components/home-increase.vue";
import homeWorkprocess from "@/components/home-workprocess.vue";
import homePartner from "@/components/home-partner.vue";
import homeContact from "@/components/home-contact.vue";
import axios from "axios";
import Json from "../../public/json/goinix-official-website-config.json";

export default {
  name: "HomeView",
  components: {
    homeHeader,
    homeBanner,
    homeBusiness,
    homeAbout,
    homeChallenge,
    homeSolution,
    homeScenario,
    homeIncrease,
    homeWorkprocess,
    homePartner,
    homeContact,
  },
  data() {
    return {
      navMenu: {},
      banners: [],
      business: [],
      about: {},
      challenge: {},
      solution: {},
      scenario: {},
      increase: {},
      workProcess: [],
      partner: {},
      contact: {},
      globalConfig: {},
    };
  },
  async mounted() {
    await this.getOnlineJson();
    this.$nextTick(() => {
      this.navMenu = this.globalConfig.navMenu;
      this.banners = this.globalConfig.banners;
      this.business = this.globalConfig.business;
      this.about = this.globalConfig.about;
      this.challenge = this.globalConfig.challenge;
      this.solution = this.globalConfig.solution;
      this.scenario = this.globalConfig.scenario;
      this.increase = this.globalConfig.increase;
      this.workProcess = this.globalConfig.workProcess;
      this.partner = this.globalConfig.partner;
      this.contact = this.globalConfig.contact;
    });
  },
  methods: {
    ...mapMutations(["updateGlobalConfig"]),
    async getOnlineJson() {
      if (process.env.NODE_ENV === "development") {
        this.globalConfig = Json;
        return;
      }
      await axios
        .get(
          `https://oss.goinix.com/upload/oss_31/goinix-official-website-config.json?t=${new Date().getTime()}`
        )
        .then((res) => {
          this.globalConfig = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.globalConfig = Json;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

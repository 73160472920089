<template>
  <div class="home-workprocess start-bg">
    <div class="home-workprocess-title margin-bottom-30">工作流程</div>
    <div class="home-workprocess-list">
      <div
        class="home-workprocess-item"
        v-for="(item, index) in data"
        @mouseenter="handleMouseMove('enter', index)"
        @mouseleave="handleMouseMove('leave', index)"
        :key="index"
      >
        <img
          class="home-workprocess-item-icon"
          :src="
            currentIndex === index
              ? item.activeImage
              : item.image
          "
          :alt="item.title"
        />
        <div class="home-workprocess-item-title">{{ item.title }}</div>
        <div class="home-workprocess-item-subtitle">{{ item.subTitle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: -1,
    };
  },
  methods: {
    handleMouseMove(type, index) {
      if (type === "enter") {
        this.currentIndex = index;
      } else {
        this.currentIndex = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-workprocess {
  width: 100%;
  padding: 110px 30px;
  .home-workprocess-title {
    color: #fff;
    font-size: $font-size-h3;
    font-weight: bold;
  }
  .home-workprocess-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .home-workprocess-item {
      width: 200px;
      color: #fff;
      cursor: pointer;
      .home-workprocess-item-subtitle {
        opacity: 0.6;
      }
      img {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1378px) {
  .home-workprocess {
    padding-bottom: 0;
    .home-workprocess-list {
      justify-content: flex-start !important;
      .home-workprocess-item {
        margin-bottom: 50px;
        margin-right: calc((100% - 600px) / 3) !important;
      }
      .home-workprocess-item:nth-of-type(3n + 0) {
        margin-right: 0;
      }
    }
  }
}
</style>
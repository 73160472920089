<template>
  <div class="home-solution" id="solution">
    <div class="home-solution-title margin-bottom-30">{{ data.title }}</div>
    <div class="home-solution-list">
      <div
        class="home-solution-item"
        v-for="(item, index) in data.list"
        :key="index"
      >
        <img :src="item.image" :alt="item.title" />
        <div class="home-solution-item-title">{{ item.title }}</div>
        <div class="home-solution-item-desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.home-solution {
  width: 100%;
  padding: 110px 75px;
  .home-solution-title {
    text-align: center;
    font-size: $font-size-h3;
    font-weight: bold;
  }
  .home-solution-list {
    display: flex;
    justify-content: space-around;
    .home-solution-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 350px;
      img {
        width: 100%;
        height: 200px;
        object-fit: contain;
      }
      .home-solution-item-title {
        font-weight: bold;
        font-size: $font-size-base;
        white-space: pre-wrap;
        margin-top: 15px;
      }
      .home-solution-item-desc {
        margin-top: 10px;
        white-space: pre-wrap;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1378px) {
  .home-solution-item {
    width: 275px !important;
    height: 180px !important;
    .home-solution-item-title {
      font-size: $font-size-small !important;
    }
    .home-solution-item-desc {
      font-size: $font-size-xsmall !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-solution-list {
    flex-wrap: wrap !important;
  }

  .home-solution-item {
    img {
      margin-top: 50px !important;
    }
  }

  .home-solution-item-title {
    font-size: $font-size-small !important;
  }
  .home-solution-item-desc {
    font-size: $font-size-xsmall !important;
  }
}
</style>
<template>
  <div class="video-slider" id="home">
    <swiper :options="swiperOptions">
      <swiper-slide
        class="slider-item"
        v-for="(item, index) in banners"
        :key="item.id"
      >
        <video
          class="video-player"
          :src="item.bannerVideoUrl"
          loop
          autoplay
          muted="muted"
        ></video>
        <img
          class="video-banner-text"
          :src="item.bannerTextImg"
          alt=""
        />
        <div class="banner-text">
          <a class="banner-link" @click="playVideo(item.videoUrl)"
            >PLAY VIDEO</a
          >
        </div>
        <iframe
          class="video-iframe"
          :src="item.videoD3"
          frameborder="0"
        ></iframe>
      </swiper-slide>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    <!-- hide -->
    <div class="home-banner-video" ref="homeBannerVideo" v-if="isPlayVideo">
      <video
        v-if="isPlayVideo"
        :src="playingVideoUrl"
        class="home-banner-video-player"
        autoplay
        controls
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isPlayVideo: false,
      playingVideoUrl: "",
      swiperOptions: {
        // 轮播选项
        height: "100%",
        speed: 500, //切换速度
        watchOverflow: true, //当没有足够的slide切换时，例如只有1个slide（非loop），swiper会失效且隐藏导航等。默认不开启这个功能。
        loop: false, //开启循环模式
        loopAdditionalSlides: 3,
        slidesPerView: 1,
        spaceBetween: 0,
        preventClicksPropagation: true, //阻止click冒泡。拖动Swiper时阻止click事件。
        simulateTouch: true, //鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //修改分页器样式
        bulletClass: "swiper-pagination-bullet",
        //激活样式
        bulletActiveClass: "my-bullet-active",
      },
    };
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    playVideo(url) {
      console.log(url);
      this.isPlayVideo = true;
      this.playingVideoUrl = url;
      console.log(this.isPlayVideo);
    },
    bodyCloseMenus(e) {
      let self = this;
      if (
        this.$refs.homeBannerVideo &&
        this.$refs.homeBannerVideo.contains(e.target)
      ) {
        if (self.isPlayVideo == true) {
          self.isPlayVideo = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-slider {
  width: 100%;
  position: relative;
  // cursor: pointer;

  .slider-item {
    width: 100% !important;
    position: relative;
    .video-player {
      width: 100%;
      // height: 800px;
      background-color: #000;
      // object-fit: cover;
    }
    .video-banner {
      display: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .video-banner-text {
      width: 25%;
      // height: 20%;
      position: absolute;
      top: 20%;
      left: 10%;
      object-fit: contain;
    }
    .banner-text {
      text-align: center;
      position: absolute;
      margin: auto;
      // right: 0;
      top: 60%;
      left: 10%;
      color: #fff;
      pointer-events: all;
      z-index: 9;
      .banner-link {
        display: block;
        width: 167px;
        height: 54px;
        margin: 0 auto;
        margin-top: 1.5%;
        border: 2px solid rgba(255, 255, 255, 0.5);
        line-height: 54px;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        letter-spacing: 3px;
        font-weight: 600;

        &:hover {
          background-color: rgba(255, 255, 255, 0.9);
          color: #333 !important;
        }
      }
    }
    .video-iframe {
      position: absolute;
      width: 40%;
      height: 75%;
      bottom: 1%;
      right: 5%;
      z-index: 10;
    }
  }

  .home-banner-video {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    .home-banner-video-player {
      width: 65%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
@media (max-width: 768px) {
  .video-banner-text {
    width: 50% !important;
  }
  .banner-text {
    top: 60% !important;
    .banner-link {
      width: 150px !important;
      height: 50px !important;
      line-height: 50px !important;
      font-weight: normal !important;
    }
  }
}
</style>